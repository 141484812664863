export function readFromCookieOrLocalStorage(key) {
    let value = readCookieValue(key);
    if (!value) {
        value = localStorage[key];
    }
    return value;
}

export function readCookieValue(cookieName) {
    const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));
    if (match) {
        return match[2];
    }
}

export function appendHtml(container, html) {
    const range = document.createRange();
    range.setStart(container, 0);
    range.setEnd(container, 0);
    container.appendChild(range.createContextualFragment(html));
}