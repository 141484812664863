export { fetchRetryTimeout };

async function fetchRetryTimeout(url, config) {
    return await submitWithRetry(url, config);
}

async function submitWithRetry(url, config, attemptCount = 1) {
    try {
        return await submitWithTimeout(url, config);
    } catch (e) {
        return retryOrNull(url, attemptCount, config);
    }
}

async function submitWithTimeout(url, config) {
    const additionalConfig = {
        credentials: 'same-origin',
        signal: requestTimeoutSignal()
    };
    const options = Object.assign({}, additionalConfig, config);

    return await submit(url, options);
}

function requestTimeoutSignal() {
    const controller = new AbortController();
    setTimeout(() => {
        controller.abort();
    }, 5000);
    return controller.signal;
}

async function submit(url, config) {
    try {
        const response = await fetch(url, config);
        if (response.status !== 200 && response.status !== 400) {
            console.log('fetch-Response:', response);
            throw new Error('Request failed');
        }
        return response;
    } catch (error) {
        if (error.name === 'AbortError') {
            throw new Error('Request timed out');
        }
        throw error;
    }
}

function retryOrNull(url, attemptCount, state) {
    if (attemptCount < 1) {
        return submitWithRetry(url, state, csrf, attemptCount + 1);
    } else {
        return null;
    }
}
