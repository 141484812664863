import { fetchRetryTimeout } from "../../lib/request-retry-timeout";
import { appendHtml, readFromCookieOrLocalStorage } from "../../lib/utils";

class KundeninteressenLoader extends HTMLElement {
  connectedCallback() {
    this.handleRegistrierung();
    this.initLoginEventListener();
  }

  initLoginEventListener() {
    window.addEventListener(this.loginEventName, (event) => {
      if (!event.detail || event.detail.neuerStatus !== "ausgeloggt") {
        if (this.cookie !== 'true') {
          this.handleLogin();
        }
      }
    })
  }

  handleRegistrierung() {
    if (window.location.search === "?neukunde=ja") {
      this.handleLogin();
    }
  }

  async handleLogin() {
    const response = await this.ladeKundeninteressen();
    if (response && response.status === 200 && response.redirected !== true) {
      const snippetHtml = await response.text();
      appendHtml(document.body, snippetHtml);
      this.setCookie();
    }
  }

  async ladeKundeninteressen() {
    const overlaySnippetUrl = this.dataset.loadUrl;
    return await fetchRetryTimeout(overlaySnippetUrl);
  }

  setCookie() {
    const maxAgeValue = 2147483647; // Maximum value
    const cookieString = `${this.cookieName}=true;max-age=${maxAgeValue};path=/`;
    document.cookie = cookieString;
    localStorage[this.cookieName] = "true";
  }

  get cookie() {
    return readFromCookieOrLocalStorage(this.cookieName);
  }

  get cookieName() {
    return "kundeninteressen-gesetzt";
  }

  get loginEventName() {
    return "login.change";
  }

}

if (!customElements.get("kundeninteressen-loader")) {
  customElements.define("kundeninteressen-loader", KundeninteressenLoader);
}

(function (doc) {
  const loadUrl = doc.querySelector('[data-load-url]').dataset.loadUrl;
  appendHtml(document.body, `<kundeninteressen-loader data-load-url=${loadUrl}></kundeninteressen-loader>`);
})(document);